











import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import DatePickerBase from '@/components/atoms/DatePickerBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'

@Component({
  components: {
    DatePickerBase,
    SelectBase,
  },
})
export default class RangeDatePicker extends Mixins(LocalMoment) {
  private optionDatas: object[] = [{ text: '過去14日間', value: 0 }]

  @Ref() startDatePickerBase!: DatePickerBase
  @Ref() endDatePickerBase!: DatePickerBase

  private mounted() {
    // 初期値は過去14日
    this.startDatePickerBase.selectedDate = this.beforeDays(14).toDate()
  }
}
