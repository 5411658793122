














import { Component, Prop, Vue } from 'vue-property-decorator'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'

@Component({
  components: {
    CheckboxSquare,
  },
})
export default class CheckboxWithLabel extends Vue {
  @Prop()
  items!: []
}
